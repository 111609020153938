import React, { useState } from 'react'
import logo from '../assets/img/logo1.png'
import axios from '../api/axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUser } from '../redux/actions/user'
import { CONSTANTS } from '../utils/constants/constants'

const LoginPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState({
		username: '', secret_key: CONSTANTS.secret_key, password: ''
	})

  const valueFromInput = (val, property) => {
		setInputValue({ ...inputValue, [property]: val })
	}

  const handleLogin = async (e) => {
		e.preventDefault();
		try {
			await dispatch(getUser(inputValue))
            
            navigate('/dashboard')
		} catch (error) {
			console.log(error)
		}
	}



  return (
    <>
      <div className="login-page-wrap">
        <div className="login-page-content">
            <div className="login-box">
                <div className="item-logo">
                    <img src={logo} alt="logo"/>
                </div>
                <form className="login-form">
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" placeholder="Enter usrename" className="form-control" onChange={(e) => valueFromInput(e.target.value, "username")}/>
                        <i className="far fa-envelope"></i>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="text" placeholder="Enter password" className="form-control" onChange={(e) => valueFromInput(e.target.value, "password")}/>
                        <i className="fas fa-lock"></i>
                    </div>
                    <div className="form-group d-flex align-items-center justify-content-between">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="remember-me"/>
                            <label htmlFor="remember-me" className="form-check-label">Remember Me</label>
                        </div>
                        <a href="#" className="forgot-btn">Forgot Password?</a>
                    </div>
                    <div className="form-group">
                        <button type="button" className="login-btn" onClick={(e) => handleLogin(e)}>Login</button>
                    </div>
                </form>
                
            </div>
        </div>
    </div> 
    </>
  )
}

export default LoginPage
