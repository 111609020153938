import axios from '../../api/axios'

export const getUser = (body) => async (dispatch)=>{
    try {
        dispatch({type: "getUserRequest"});
        const res = await axios.post('/authentication', body)
        
       await dispatch({type: "getUserSuccess", payload: res.data.data.user_basic_detail})
       await dispatch({type: "getModuleSuccess", payload: res.data.data.module_permission})
       await dispatch({type: "getPermissionSuccess", payload: res.data.data.data_permission})
    } catch (error) {
        dispatch({type: "getUserFail", payload: error.response})
    }
}

export const getAllStudents = (body) => async (dispatch) => {
    try {
        dispatch({type: "getAllStudentsRequest"})
        const res = await axios.get('/student_list', body)
        
        await dispatch({type: "getAllStudentsSuccess", payload: res.data.data})
    } catch (error) {
        dispatch({type: "getAllStudentsFail", payload: error.response})
    }
}