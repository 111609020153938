//Components files 

// import Header from "./components/header";
// import Sidebar from "./components/Sidebar";
// import Breadcrumbs from "./components/Breadcrumbs";
// import Button from "./components/Button";
// import Card from "./components/Card";
// import Card2 from "./components/Card2";
// import Table from './components/Table';
// import Dropdown from './components/Dropdown';

// Styling files 
import './assets/css/animate.css';
import './assets/css/bootstrap.min.css';
import './assets/css/normalize.css';
import './assets/css/all.css';
import './assets/css/main.css';
import './assets/css/select2.css';
import './assets/css/fullcalendar.css';
import './assets/css/datepicker.css';
import './assets/css/jquerydataTables.css';
import './assets/css/style.css';
import './assets/fonts/flaticon.css'
import Router from "./router/Router";

function App() {
  return (
    <>
      <Router />

    </>
  );
}

export default App;
