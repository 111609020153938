import React from 'react'
import Header from '../components/header'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <div id="wrapper" className="wrapper bg-ash">
        <Header/>
        <div className="dashboard-page-one">
            <Sidebar/>
            <div className="dashboard-content-one">
                <Outlet/>
                
            </div>
        </div>
    </div>
  )
}

export default Layout
