import { createReducer } from "@reduxjs/toolkit";

const allStudentsReducer = createReducer(
    { allStudents: {}},
    {
        getAllStudentsRequest: (state)=> {
            state.loading = true
        },
        getAllStudentsSuccess: (state, action)=> {
            state.loading = false;
            state.allStudents = action.payload
        },
        getAllStudentsFail: (state, action)=> {
            state.loading = false;
            state.error = action.payload
        },
    }
)

export default allStudentsReducer