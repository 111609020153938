import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore} from 'redux-persist'
import thunk from "redux-thunk";
import moduleReducer from "./reducers/moduleReducer";
import permissionReducer from "./reducers/permissionReducer";
import allStudentsReducer from "./reducers/studentsReducer";

const persistConfig = {
    key: 'root',
    storage
}
const rootReducer = combineReducers({
    user: userReducer,
    module: moduleReducer,
    permission: permissionReducer,
    allStudents: allStudentsReducer
})

const persistedReducer = persistReducer (persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)