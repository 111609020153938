import { createReducer } from "@reduxjs/toolkit";

const moduleReducer = createReducer(
    { module: {}},
    {
        getModuleRequest: (state)=> {
            state.loading = true
        },
        getModuleSuccess: (state, action)=> {
            state.loading = false;
            state.module = action.payload
        },
        getModuleFail: (state, action)=> {
            state.loading = false;
            state.error = action.payload
        },
    }
)

export default moduleReducer