import React, { useEffect, useMemo, useState } from 'react'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import axios from '../../api/axios';
import { Avatar } from '@mui/material';
import ActionButton from './ActionButton';
import { CONSTANTS } from '../../utils/constants/constants';
import { useSelector, useDispatch } from 'react-redux'
import { getAllStudents } from '../../redux/actions/user';

const MuiDataGrid = () => {

  const { user } = useSelector((state) => state.user)
  const { permission } = useSelector((state) => state.permission)
  const { allStudents } = useSelector((state) => state.allStudents)
  const dispatch = useDispatch()


  const [data, setData] = useState([])
  const [rowId, setRowId] = useState()
  const [singleUser, setSingleUser] = useState({})

const axiosParams = {
  params: {
    secret_key: CONSTANTS.secret_key,
    school_id: permission.schools[0].id,
    user_id: user.id

  }
}


  const getStudentsData = async () => {
    try {
      await dispatch(getAllStudents(axiosParams))
    } catch (error) {
      console.log(error)
    }
  }
  
  
  useEffect(() => {
    getStudentsData()
    setData(allStudents)
  }, [])

  console.log(allStudents)

  var columns = useMemo(() => [
    { field: 'role_num', headerName: 'Roll Number', width: 150, },
    { field: 'StdFirstName', headerName: 'Student Name', width: 200, },
    { field: 'parent_data', headerName: 'Father Name', width: 200, valueGetter: (tableData)=> tableData.row.parent_data.PName},
    { field: 'actions', headerName: 'Action', width: 100, type: 'actions', renderCell: params => <ActionButton {...{ params, rowId, setRowId }} /> }
  ], [rowId])

  return (
    <div style={{ height: 350, width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={data}
        getRowId={(row) => row.id}
        onCellEditCommit={params => { setRowId(params.id) }}
        sx={{fontSize: '16px'}}
        
      />
    </div>
  )
}

export default MuiDataGrid