import { createReducer } from "@reduxjs/toolkit";

const permissionReducer = createReducer(
    { permission: {}},
    {
        getPermissionRequest: (state)=> {
            state.loading = true
        },
        getPermissionSuccess: (state, action)=> {
            state.loading = false;
            state.permission = action.payload
        },
        getPermissionFail: (state, action)=> {
            state.loading = false;
            state.error = action.payload
        },
    }
)

export default permissionReducer