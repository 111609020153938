import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import MuiDataGrid from '../components/muiDataGrid/MuiDataGrid'

const StudentsList = () => {
  return (
    <>
      <Breadcrumbs title="Student List" pageName="student-list"/>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="item-title">
              <h3>All Students Data</h3>
            </div>
            
          </div>

          <div className="table-responsive">
            <MuiDataGrid/>

          </div>
        </div>
      </div>
    </>
  )
}

export default StudentsList
