import React, { useEffect, useState } from 'react'
import logo from '../assets/img/logo1.png'
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Sidebar = () => {

    const [sideMenuItem, setSideMenuItem] = useState([])

    const { module } = useSelector((state) => state.module)

    useEffect(() => {
        setSideMenuItem(module)
        $('.sidebar-toggle-view').on('click', '.sidebar-nav-item .nav-link', function (e) {
            if (!$(this).parents('#wrapper').hasClass('sidebar-collapsed')) {
                var animationSpeed = 300,
                    subMenuSelector = '.sub-group-menu',
                    $this = $(this),
                    checkElement = $this.next();
                if (checkElement.is(subMenuSelector) && checkElement.is(':visible')) {
                    checkElement.slideUp(animationSpeed, function () {
                        checkElement.removeClass('menu-open');
                    });
                    checkElement.parent(".sidebar-nav-item").removeClass("active");
                } else if ((checkElement.is(subMenuSelector)) && (!checkElement.is(':visible'))) {
                    var parent = $this.parents('ul').first();
                    var ul = parent.find('ul:visible').slideUp(animationSpeed);
                    ul.removeClass('menu-open');
                    var parent_li = $this.parent("li");
                    checkElement.slideDown(animationSpeed, function () {
                        checkElement.addClass('menu-open');
                        parent.find('.sidebar-nav-item.active').removeClass('active');
                        parent_li.addClass('active');
                    });
                }
                if (checkElement.is(subMenuSelector)) {
                    e.preventDefault();
                }
            } else {
                if ($(this).attr('href') === "#") {
                    e.preventDefault();
                }
            }
        });

        //   /*-------------------------------------
        //       Sidebar Menu Control
        //     -------------------------------------*/
        //   $(".sidebar-toggle").on("click", function () {
        //     window.setTimeout(function () {
        //       $("#wrapper").toggleClass("sidebar-collapsed");
        //     }, 500);
        //   });

        //   /*-------------------------------------
        //       Sidebar Menu Control Mobile
        //     -------------------------------------*/
        //   $(".sidebar-toggle-mobile").on("click", function () {
        //     $("#wrapper").toggleClass("sidebar-collapsed-mobile");
        //     if ($("#wrapper").hasClass("sidebar-collapsed")) {
        //       $("#wrapper").removeClass("sidebar-collapsed");
        //     }
        //   });
    }, [])


    return (
        <div>
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none">
                    <div className="header-logo">
                        <a href="index.html"><img src={logo} alt="logo" /></a>
                    </div>
                </div>
                <div className="sidebar-menu-content">
                    <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                        {sideMenuItem && sideMenuItem.map((val) => (
                            <li className="nav-item sidebar-nav-item" key={val.id}>

                                {val.prefix_or_url !== '' ? <Link to={`/${val.prefix_or_url}`}><span className="nav-link"><i className={val.icon_class}></i><span>{val.name}</span></span></Link>
                                    : <span className="nav-link"><i className={val.icon_class}></i><span>{val.name}</span></span>
                                }


                                {val.children_data && (
                                    <ul className="nav sub-group-menu">
                                        {val.children_data.map(child => (
                                            <li className="nav-item" key={child.id}>
                                                <Link to={`/${child.prefix_or_url}`} className="nav-link"><i className={child.icon_class}></i>{child.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}

                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Sidebar
