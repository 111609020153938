import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import StudentsList from '../pages/StudentsList'
import LoginPage from '../pages/Login'
import Header from '../components/header'
import Sidebar from '../components/Sidebar'
import Layout from '../layout/Layout'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route element={<Layout />}>
          <Route index path='dashboard' element={<Dashboard />} />
          <Route path='student-list' element={<StudentsList />} />

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
