import { createReducer } from "@reduxjs/toolkit";

const userReducer = createReducer(
    { user: {}},
    {
        getUserRequest: (state)=> {
            state.loading = true
        },
        getUserSuccess: (state, action)=> {
            state.loading = false;
            state.user = action.payload
        },
        getUserFail: (state, action)=> {
            state.loading = false;
            state.error = action.payload
        },
    }
)

export default userReducer