import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  return (
    <div>
      <div className="breadcrumbs-area">
                    <h3>{props.title}</h3>
                    <ul>
                        <li>
                            <Link to="/dashboard">Home</Link>
                        </li>
                        <li>{props.pageName}</li>
                    </ul>
                </div>
    </div>
  )
}

export default Breadcrumbs
